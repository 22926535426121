@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: #1a1a1a;
}
.App {
  text-align: center;
  fill: white; 
  /* font-family: "Comic Sans MS", "Comic Sans", cursive; */
  font-family: 'Enchanted land', sans-serif;
  background-color: #333;
  color: #000;
  min-height: 98vh;
  font-size: 22px;
}

.App-header {
  /* background-color: #ffffff; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 36px;
  /* font-size: calc(10px + 2vmin); */
  /* color: #000000; */
}


.Container {
  margin-top: 100px;
  max-width: 960px;
  min-width: 640px;
  min-height: 360px;
  background-color: #333;
  border-style: solid;
  border-width: 7px;
  border-color: #000000;
  border-radius: 15px;
}

h1 {
  font-size: 5rem;
  font-weight: 400;
  margin: 24px 0;
}

p {
  margin: 0px 48px;
  color: #8e8e8e;
}

.grid2x2{
  justify-content: center;
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 60px 60px;
}

.grid2x2 > div {
  display: flex;
  margin: 12px;
  /* font-weight: bold; */
  flex-basis: 200px;
  font-size: 36px;
  justify-content: center;
  flex-direction: column;
  border-style: solid;
  border-width: thick;
  border-color: #000000;
  border-radius: 13px;
  color: #000;
  cursor: pointer;
}



@media (max-width: 660px) {
  .App-header {
    padding: 8px 36px;
  }

  .Container {
    min-width: 100%;
    min-height: 100%;
  }
  .grid2x2{
    margin: 32px 32px;
  }

  .phantom {
    display: none !important;
  }

  p {
    margin: 0px 24px !important;
  }
}

.toastFont {
  font-size: 1rem;
  background-color: #000 !important;
}
